import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit'
import { useDispatch, useSelector } from 'react-redux'
import { createFileSeqActionSlice } from '../../app/redux/slices/createFileSeqActionSlice'
import { createFolderSeqActionSlice } from '../../app/redux/slices/createFolderSeqActionSlice'
import { deleteFolderSeqActionSlice } from '../../app/redux/slices/deleteFolderSeqActionSlice'
import { renameFileSeqActionSlice } from '../../app/redux/slices/renameFileSeqActionSlice'
import { AppDispatch } from '../../app/redux/store'
import style from './ContextMenu.module.scss'
interface ContextMenuProps {
    path: string | null,
    type: string,
    isContext: boolean
}
export const ContextMenu:React.FC<ContextMenuProps> = ({path, type, isContext}) => {
    const dispatch = useDispatch<AppDispatch>()
    const PathDirectory = useSelector((state:any)=>state.CurrentDirectorySlice) 
  
    const dispatchThunk = useDispatch<ThunkDispatch<{}, {}, AnyAction>>();
    const handleDelete = (path:string | null) => {
        const pathOne: string = String(path);
        dispatchThunk(deleteFolderSeqActionSlice({ path: pathOne, pathFolder:PathDirectory.path.join("/") }));
    }
    const handlerFirstFolder = () => {
        let name:string = prompt("Введите название папки, пожалуйста")+"";
        handleAddFolder(path, name)
    }
    const handlerCreateFileFirst = () => {
        let name:string = prompt("Введите название папки, пожалуйста")+"";
        let content:string = prompt("Введите контент, пожалуйста")+"";
        handleAddFile(path+"", name, content)
    }
    const handleAddFolder = (path: string | null, nameFile:string) => {

        dispatchThunk(createFolderSeqActionSlice( {
            path:path+"",
            folderName: nameFile
        }))

    }
    const handleAddFile = (path: string, nameFile:string, content:string) => {
        dispatchThunk(createFileSeqActionSlice({
            path,
            fileName: nameFile,
            content
        }))
    }
  const firstRenameFile = () => {
		let newName: string | null = prompt('Введите новое название, пожалуйста')

		// Проверка на null
		if (newName === null) {
			// Пользователь нажал "Cancel", ничего не делаем
			return
		}

		// Проверка, если пользователь не ввел ничего (пустая строка)
		if (newName.trim() === '') {
			alert('Название не может быть пустым.')
			return
		}

		// Если все в порядке, вызываем dispatch с новым именем файла
		dispatchThunk(
			renameFileSeqActionSlice({
				path,
				newName,
				pathFolder: PathDirectory.path.join('/'),
			})
		)
	}
    
    return (
        <div className={style.wrapperContextMenu} >
            <ul className={style.wrapperMenuItem}>
                <li className={style.menuItem}><button className={style.btnMenuItem} onClick={()=>handleDelete(path)}>Удалить</button></li>
                <li className={style.menuItem}><button className={style.btnMenuItem} onClick={()=>handlerFirstFolder()} >Добавить папку</button></li>
                <li className={style.menuItem}><button className={style.btnMenuItem} onClick={()=>handlerCreateFileFirst()} >Добавить файл</button></li>
               {!isContext
                && <li className={style.menuItem}><button className={style.btnMenuItem} onClick={()=>firstRenameFile()} >Переименовать файл</button></li>
               } 
            </ul>
        </div>
    )
}
