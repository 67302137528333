import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentPath } from '../../app/redux/slices/CurrentDirectorySlice'
import { setCurrentFolder } from '../../app/redux/slices/currentFolderInfo'
import { setDropDownData } from '../../app/redux/slices/DropDownSlice'
import { setCurrentDirectoryUnder } from '../../app/redux/slices/getAllDirectoriesUnder'
import { fetchMove } from '../../app/redux/slices/moveFolderSlice'
import { AppDispatch } from '../../app/redux/store'
import { BuildPath } from '../../features/BuildPath'
import getFileExtension from '../../features/FindExten'
import { FindToDeletePath } from '../../features/FindToDeletePath'
import { arrIcons } from '../../shared/IconsAndExtensio'
import triangle from '../../shared/triangle.svg'
import { FolderFace, moveProps } from '../../types/types'
import style from './Folder.module.scss'
import { setCurrentImagePath } from '../../app/redux/slices/CurrentImageSlice'
interface FolderProps extends FolderFace {
    level: number; // Добавляем уровень вложенности
}

const Folder: React.FC<FolderProps> = ({ name, type, path, isHaveContent, contents, level }) => {
    const dispatch = useDispatch<AppDispatch>();
    const {directories, error, loading} = useSelector((state:any)=>state.directoryUnderSlice) 
    const [contextMenuPath, setContextMenuPath] = useState<string | null>(null);
    const PathOne = useSelector((state: any) => state.CurrentDirectorySlice);
    const [isContextMenu, setIsContextMenu] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const [draggedItem, setDraggedItem] = useState<any>(null);

    const handleFolderClick = () => {
        if (contents && contents.length > 0 && !loading && error === null) {
            let some: any = BuildPath(name, directories);
            dispatch(setCurrentFolder(contents));
            dispatch(setCurrentPath(path));
            dispatch(setCurrentDirectoryUnder([...directories ]));
            dispatch(setDropDownData(contents))
        } else  if (contents?.length === 0 && !loading && error === null && type === 'directory')  {
           
            let some: any = BuildPath(name, directories);
            dispatch(setCurrentPath(some));
            dispatch(setCurrentFolder([]));
        }
	const result = getFileExtension(name)
        if (result) {
			dispatch(setCurrentImagePath(path))
		}
    };


    const handleExpandToggle = () => {
        setIsExpanded(!isExpanded);
    };

    const setSrc = (): string => {
        let extenOne: null | string = null;
        let typeOne: null | 'directory' = null;
        const exten: string = getFileExtension(name);
        if (exten.trim().length === 0) {
            extenOne = null;
        } else if (exten.trim().length > 0) {
            extenOne = exten;
        }
        if (type === undefined) {
            typeOne = null;
        } else if (type === 'directory') {
            typeOne = 'directory';
        }
        const result: string = arrIcons(extenOne, typeOne);
        return result;
    };

    const handleContextMenu = (event: React.MouseEvent) => {
        event.preventDefault(); // Предотвращает открытие контекстного меню
        setIsContextMenu(!isContextMenu);
    };

    const handlePath = () => {
        const result = BuildPath(name, directories);
        let arg: string | null = FindToDeletePath({ name, data: directories });
        setContextMenuPath(arg);
    };

    const handleDragStart = (event: React.DragEvent, item: any) => {
        setDraggedItem(item);
        event.dataTransfer.setData('text/plain', JSON.stringify(item));
    };

    const handleDragOver = (event: React.DragEvent) => {
        event.preventDefault();
     
    };

    const handleDrop = (event: React.DragEvent, destination: any) => {
        event.preventDefault();
         
        const droppedItem = JSON.parse(event.dataTransfer.getData('text/plain'));
        const oldPath:any = BuildPath(droppedItem.name, directories)
        let dropInFolder:string = destination.name;
        const newPath:any = BuildPath(dropInFolder, directories)
        const dataMove:moveProps = {
            oldPath, newPath
        }

        dispatch(fetchMove(dataMove));
        // Update your state or dispatch actions to reflect the movement here
    };

    useEffect(() => {
        if (isContextMenu) {
            handlePath();
        }
    }, [isContextMenu]);

    return (
        <div
            onContextMenu={handleContextMenu}
            className={style.wrapperFolder}
            style={{ marginLeft: `${level * 20}px` }}

        >
            <div className={style.folderHeader} onDoubleClick={handleFolderClick}>
                <button onClick={handleExpandToggle} className={style.wrapperTriangle}>
                    {contents && contents.length > 0 ? <img src={triangle} width={20} height={20} alt="toggle" /> : null}
                </button>
                <img width={28} height={28} src={setSrc()} alt="folder" />
                <span>{name}</span>
            </div>
            {type === 'directory' && isExpanded && contents && (
                <div className={style.contents}>
                    {contents.map((element: any) => (
                        <Folder
                            key={element.path}
                            name={element.name}
                            type={element.type}
                            path={element.path}
                            isHaveContent={element.isHaveContent}
                            contents={element.contents}
                            level={level + 1}
                        />
                    ))}
                </div>
            )}
            {/* {isContextMenu && <ContextMenu path={contextMenuPath} />} */}
        </div>
    );
};

export default Folder;
